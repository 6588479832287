:root {
  --primary-color: #086ad3;
}

.inputAudio {
  width: 40%;
  padding: 10px;
  margin: 5px 0;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.inputAudio:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(var(--primary-color), 0.7);
}

.inputAudioDropzone {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  cursor: pointer;
  padding: 20px;
  text-align: center;
  max-width: 350px;
  p {
    margin: 0;
  }
  &:hover {
    border-color: var(--primary-color);
  }
}
