.CSVPredictioncontainer {
  .title {
    font-size: 24px;
  }
  .card {
    padding: 24px;
    margin-top: 20px;
    .inputBox {
      display: flex;
      justify-content: center;
      align-items: center;
      .inputDropzone {
        border: 2px dashed #cccccc;
        border-radius: 4px;
        cursor: pointer;
        padding: 20px;
        text-align: center;
        max-width: 400px;
        p {
          margin: 0;
        }
        &:hover {
          border-color: var(--primary-color);
        }
      }
    }
    .button, .alert {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      column-gap: 20px;
    }
    .responseContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 40px;
      .textResult {
        text-align: justify;
        padding: 20px;
      }
    }
  }
  .subtitle {
    font-size: 20px;
    margin-top: 20px;
  }
}