body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#navtopleft {
  background-color: #086ad3;
}

#AppBar {
  background-color: #086ad3;
}
#divlogo{
  display:block;
}
#dashboards-header {
  display:block;
  font-size:16px;
  padding-right:29px;
}
#navegacion {
  margin-left:37px;
  font-size: 18px;
}

#navtopleft-subtitle{
  background-color: white;
}

